import React from "react";
import TripContainer from "../components/Trip Container/TripContainer";

const DashBoard = () => {
  return (
    <>
      <TripContainer />
    </>
  );

};

export default DashBoard;
